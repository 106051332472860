<template>
    <div :id="`dialog-content-${dataProduct.id}`" class="row" style="display:none;max-width: 500px;">
        <!--Middle Part Start-->
        <div class="product-view row quickview-w">
            <div class="content-product">
                <div class="class-honizol col-md-12 col-sm-12 col-xs-12">
                    <div class="large-image">
                        <img itemprop="image" class="product-image-zoom img-responsive" :src="`/uploads/${dataProduct.main_image}`"
                            @error="setAltImg" :title="dataProduct.name"
                            :alt="dataProduct.name">
                    </div>
                </div>

                <div class="col-md-12 col-sm-12 col-xs-12">
                    <div class="title-product">
                        <h3>{{ dataProduct.name }}</h3>
                    </div>
                    <div class="product-label form-group">
                        <div class="product_page_price price" v-if="allowShop">
                            <div class="price" v-if="bulk_price">
                                <span class="price-new">
                                    {{ formatterCurrency(bulk_price.exchange_sale_price) }}
                                    <br />
                                    <span class="text-secondary small" v-if="bulkPrices.length > 0">Un:
                                        {{ formatterCurrency(bulk_price.exchange_unit_sale_price) }}
                                    </span>
                                </span>
                                <span class="price-old" v-if="bulk_price.discount > 0">
                                    {{ formatterCurrency(bulk_price.exchange_original_sale_price) }}
                                </span>
                            </div>
                        </div>
                        <!-- <div class="stock">
                            <span>Stock:</span>
                            <span class="status-stock">In Stock</span>
                        </div> -->
                    </div>
                    <div id="product">
                        <div class="rating" v-if="bulkPrices.length > 0 && allowShop">
                            <label :for="`bulk_price_${dataProduct.id}_${price.max_bulk}`"
                                v-for="(price, index) in bulkPrices" :key="index">
                                <input type="radio" :id="`bulk_price_${dataProduct.id}_${price.max_bulk}`"
                                    @click="setBulkPrice(price)" :name="`bulk_price_${dataProduct.id}`"
                                    :value="price.id" v-model="bulk_price_id" />
                                X {{ price.max_bulk }}
                            </label>
                        </div>
                        <div class="description" v-html="dataProduct.description"></div>
                        <div class="list-block row" v-if="allowShop">
                            <div class="col-md-8 col-sm-12 col-xs-12 text-center">
                                <div class="input-group">
                                    <input type="number" v-model="quantity" class="inputQty form-control"
                                        :name="`input-qty-${props.dataProduct.code}`" />
                                    <span class="input-group-btn">
                                        <button class="addToCart btn btn-primary" type="button"
                                            title="Agregar al pedido" @click="addCart()">
                                            <i class="fa fa-shopping-basket"></i>
                                            Agregar
                                        </button>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- end box info product -->
                </div>
            </div>
        </div>
    </div>
    <!--Middle Part End-->
</template>

<script setup>
import useCartStore from "../stores/cart";
import { errorMessage } from "../js/util/notifications";

import {
    ref,
    onMounted,
    getCurrentInstance,
    defineExpose,
    computed,
    watch,
} from "vue";

const props = defineProps({
    dataProduct: {
        type: Object,
        required: true,
    },
    allowShop: {
        type: Boolean,
        required: true,
        default: false
    }
});

const bulk_price = ref({});
const bulk_price_id = ref(null);
const quantity = ref(1);
const loaded = ref(false);

const emit = defineEmits(["addCart"]);

const formatterPercent = (val) => {
    let percent = val / 100;
    return new Intl.NumberFormat("es-AR", {
        style: "percent",
        maximumFractionDigits: 2,
    }).format(percent);
};

const formatterCurrency = (val) => {
    if (props.currency == "USD") {
        return new Intl.NumberFormat("es-AR", {
            style: "currency",
            currency: "USD",
        }).format(val);
    } else {
        return new Intl.NumberFormat("es-AR", {
            style: "currency",
            currency: "ARS",
        }).format(val);
    }
};

const setBulkPrice = (selected_bulk_price) => {
    bulk_price.value = selected_bulk_price;
};

const setAltImg = (event) => {
  event.target.src = '/dist/intermotos/images/logo_not_image.png';
}

onMounted(() => {
    let instance = getCurrentInstance();
    loaded.value = true;
});

const bulkPrices = computed(() => {
    let default_bulk_price = {
        id: null,
        max_bulk: 1,
        allow_select_qty: 1,
        original_sale_price: Number(props.dataProduct.original_sale_price),
        sale_price: Number(props.dataProduct.sale_price),
        exchange_unit_sale_price: Number(props.dataProduct.exchange_sale_price),
        unit_sale_price: Number(props.dataProduct.sale_price),
        exchange_original_sale_price: Number(
            props.dataProduct.exchange_original_sale_price
        ),
        exchange_sale_price: Number(props.dataProduct.exchange_sale_price),
        discount: Number(props.dataProduct.discount_percentage),
    };

    let bulk_prices = JSON.parse(
        props.dataProduct.bulk_prices.substring(
            1,
            props.dataProduct.bulk_prices.length - 1
        )
    );

    if (bulk_prices.length > 0) {
        bulk_prices.push(default_bulk_price);
    }

    setBulkPrice(default_bulk_price);

    return bulk_prices.sort((a, b) => {
        return a.max_bulk - b.max_bulk;
    });
});

const addCart = async () => {
  let dataCart = {
    id: props.dataProduct.id,
    price_list_id: props.dataProduct.price_list_id,
    quantity: quantity.value,
    name: `${props.dataProduct.name}`,
    unit_price: bulk_price.value.sale_price,
    //options
    bulk_items: bulk_price.value.max_bulk,
    bulk_price: bulk_price.value.sale_price,
    bulk_unit_price: bulk_price.value.unit_sale_price,
    image: props.dataProduct.main_image,
  };
  // let response = await services.shoppingcart.add(dataCart);
  useCartStore.addItem(dataCart);
};

defineExpose({});
</script>

<style>
.modal-product-wrapper {
    box-shadow: none;
}

.product-info .product-view,
.left-content-product,
.box-info-product {
    margin: 0;
}

.left-content-product .content-product-right .box-info-product .cart input {
    padding: 12px 16px;
}

.left-content-product .content-product-right .box-info-product .add-to-links {
    width: auto;
    float: none;
    margin-top: 0px;
    clear: none;
}

.add-to-links ul li {
    margin: 0;
}
</style>