function randomString(lenght) {
  var string = Math.random()
    .toString(36)
    .slice(-(lenght === "undefined" ? 6 : lenght));
  return string;
}

function urlAmigable(str) {
  var tildes = "ÃÀÁÄÂÈÉËÊÌÍÏÎÒÓÖÔÙÚÜÛãàáäâèéëêìíïîòóöôùúüûÑñÇç-",
    conver = "AAAAAEEEEIIIIOOOOUUUUaaaaaeeeeiiiioooouuuunncc_",
    cuerpo = {};

  for (var i = 0, j = tildes.length; i < j; i++) {
    cuerpo[tildes.charAt(i)] = conver.charAt(i);
  }
  str.replace(/^\s\s*/, "").replace(/\s\s*$/, "");

  var salida = [];
  for (var i = 0, j = str.length; i < j; i++) {
    var c = str.charAt(i);
    if (cuerpo.hasOwnProperty(str.charAt(i))) {
      salida.push(cuerpo[c]);
    } else {
      salida.push(c);
    }
  }
  salida = salida
    .join("")
    .replace(/[^-A-Za-z0-9!#@$&()_]+/g, "-")
    .toLowerCase();
  return salida;
}

function rawurldecode(str) {  
  // Decodes URL-encodes string    
  //   
  // version: 901.1411  
  // discuss at: http://phpjs.org/functions/rawurldecode  
  // +   original by: Brett Zamir  
  // *     example 1: rawurldecode('Kevin+van+Zonneveld%21');  
  // *     returns 1: 'Kevin+van+Zonneveld!'  
  // *     example 2: rawurldecode('http%3A%2F%2Fkevin.vanzonneveld.net%2F');  
  // *     returns 2: 'http://kevin.vanzonneveld.net/'  
  // *     example 3: rawurldecode('http%3A%2F%2Fwww.google.nl%2Fsearch%3Fq%3Dphp.js%26ie%3Dutf-8%26oe%3Dutf-8%26aq%3Dt%26rls%3Dcom.ubuntu%3Aen-US%3Aunofficial%26client%3Dfirefox-a');  
  // *     returns 3: 'http://www.google.nl/search?q=php.js&ie=utf-8&oe=utf-8&aq=t&rls=com.ubuntu:en-US:unofficial&client=firefox-a'  
  var histogram = {};  
  var ret = str.toString();   

  var replacer = function(search, replace, str) {  
      var tmp_arr = [];  
      tmp_arr = str.split(search);  
      return tmp_arr.join(replace);  
  };  

  // The histogram is identical to the one in urlencode.  
  histogram["'"]   = '%27';  
  histogram['(']   = '%28';  
  histogram[')']   = '%29';  
  histogram['*']   = '%2A';  
  histogram['~']   = '%7E';  
  histogram['!']   = '%21';  

  for (let replace in histogram) {  
      let search = histogram[replace]; // Switch order when decoding  
      ret = replacer(search, replace, ret) // Custom replace. No regexing  
  }  

  // End with decodeURIComponent, which most resembles PHP's encoding functions  
  ret = decodeURIComponent(ret);  

  return ret;  
}  


function stringFormat() {
  var s = arguments[0];
  for (var i = 0; i < arguments.length - 1; i++) {
    var reg = new RegExp("\\{" + i + "\\}", "gm");
    s = s.replace(reg, arguments[i + 1]);
  }
  return s;
}

function showLoading() {
  let body = $("body");
  let loading = $("#loading");
  loading.show();
  body.addClass("is_loading");
}

function hideLoading() {
  let body = $("body");
  let loading = $("#loading");
  loading.hide();
  body.removeClass("is_loading");
}

function initDatepicker($scope) {
  console.log("iniciando datepickers");
  $scope = $scope || "body";
  var datepickers = $($scope + " .datepicker-input");
  var datetimepickers = $($scope + " .datetimepicker-input");
  $.each(datepickers, function (key, item) {
    $(item).datetimepicker({
      format: "D/MM/Y",
      locale: "es",
    });
    console.log(
      "Scope [" + $scope + "]: datepicker " + $(item).attr("id") + " OK"
    );
  });

  $.each(datetimepickers, function (key, item) {
    $(item).datetimepicker({
      format: "D/MM/Y H:mm",
      locale: "es",
    });
    console.log(
      "Scope [" + $scope + "]: datetimepicker " + $(item).attr("id") + " OK"
    );
  });
}
function initAutocomplete($scope) {
  console.log("iniciando autocompletes");
  $scope = $scope || "body";
  var autocom = $($scope + " .select2-control");
  $.each(autocom, function (key, item) {
    if ($(item).data("select2")) {
      $(item).select2("destroy");
    }
    $(item).select2({
      placeholder: "Buscar...",
      allowClear: true,
      ajax: {
        //2019-10-07 COMENTADO POR DIEGO, ahora hay que mandarle toda la url
        //url: app.config.urls.adminbaseurl+$(item).data('url-ajax'),
        url: $(item).data("url-ajax"),
        dataType: "json",
        data: function (params) {
          var query = {
            search: params.term,
            type: "public",
            extra: $(item).data("extradata"),
          };
          return query;
        },
        processResults: function (data) {
          // Tranforms the top-level key of the response object from 'items' to 'results'
          return {
            results: data.items,
          };
        },
        // Additional AJAX parameters go here; see the end of this chapter for the full code of this example
      },
      templateResult: function (data) {
        var search = $(item)
          .data("select2")
          .dropdown.$search.val()
          .toUpperCase();
        return data.text.toUpperCase().replace(search, "<b>" + search + "</b>");
      },
      templateSelection: function (data) {
        return data.text.toUpperCase();
      },
      escapeMarkup: function (markup) {
        return markup;
      },
    });

    if ($(item).data("val") !== "" && $(item).data("val") != undefined) {
      $.ajax({
        type: "GET",
        async: false,
        url: $(item).data("url-ajax") + "?search=" + $(item).data("val"),
      }).then(function (data) {
        var obj = JSON.parse(data);
        // create the option and append to Select2
        if (obj.items.length >= 1) {
          $.each(obj.items, function (key, val) {
            $(item)
              .append(new Option(val.text, val.id, true, true))
              .trigger("change");
          });
        }
        // manually trigger the `select2:select` event
        $(item).trigger({
          type: "select2:select",
          params: {
            data: data,
          },
        });
      });
    }
    console.log(
      "Scope [" + $scope + "]: select2 " + $(item).attr("id") + " OK"
    );
  });
}

function formReadonly(form) {
  var inputs = $(form).find(
    'input[type="text"], input[type="number"], input[type="date"], input[type="email"], input[type="checkbox"], input[type="radio"], input[type="tel"], input[type="file"]'
  );
  var selects = $(form).find("select");
  var textareas = $(form).find("textarea");
  var btnSubmit = $(form).find(":submit");

  btnSubmit.hide();

  inputs.each(function (i, item) {
    $(item).attr("disabled", "disabled");
  });

  textareas.each(function (i, item) {
    $(item).attr("disabled", "disabled");
  });

  selects.each(function (i, item) {
    if ($(item).hasClass("select2-control")) {
      $(item).select2({ minimumResultsForSearch: Infinity });
    } else {
      //text = $(item).children(':selected').text();
      $(item).attr("disabled", "disabled");
    }
  });
}

function formEnabled(form) {
  var inputs = $(form).find(
    'input[type="text"], input[type="number"], input[type="date"], input[type="email"], input[type="checkbox"], input[type="radio"], input[type="tel"], input[type="file"]'
  );
  var selects = $(form).find("select");
  var textareas = $(form).find("textarea");
  var btnSubmit = $(form).find(":submit");

  btnSubmit.show();

  inputs.each(function (i, item) {
    $(item).removeAttr("disabled");
  });

  textareas.each(function (i, item) {
    $(item).removeAttr("disabled");
  });

  selects.each(function (i, item) {
    if ($(item).hasClass("select2-control")) {
      $(item).select2({ minimumResultsForSearch: 1 });
    } else {
      $(item).removeAttr("disabled");
    }
  });
}

function dynamicSort(array, sortBy) {
  return array.sort((a, b) => {
      return sortBy.reduce((result, current) => {
          if (result !== 0) return result;

          const { property, order = 'asc' } = current;

          let comparison = 0;
          if (a[property] > b[property]) {
              comparison = 1;
          } else if (a[property] < b[property]) {
              comparison = -1;
          }

          if (order === 'desc') {
              comparison *= -1;
          }

          return comparison;
      }, 0);
  });
}

function filterNumber(evt, input) {
  // Backspace = 8, Enter = 13, ‘0′ = 48, ‘9′ = 57, ‘.’ = 46, ‘-’ = 43
  var key = window.Event ? evt.which : evt.keyCode;
  var chark = String.fromCharCode(key);
  var tempValue = input.value + chark;
  if (key >= 48 && key <= 57) {
    if (filter(tempValue) === false) {
      return false;
    } else {
      return true;
    }
  } else {
    if (key === 8 || key === 13 || key === 0) {
      return true;
    } else {
      return false;
    }
  }
}

function filterEmail(input) {
  var $email = $(input); //change form to id or containment selector
  var re = /[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}/gim;
  if ($email.val() === "" || !re.test($email.val())) {
    return false;
  }
}

function filterFloat(evt, input) {
  // Backspace = 8, Enter = 13, ‘0′ = 48, ‘9′ = 57, ‘.’ = 46, ‘-’ = 43, `,` = 44
  var key = window.Event ? evt.which : evt.keyCode;
  var chark = String.fromCharCode(key);
  var tempValue = input.value + chark;
  if (key >= 48 && key <= 57) {
    return filter(tempValue);
  } else {
    if (key === 8 || key === 13 || key === 0) {
      return true;
    } else if (key === 44) {
      return filter(tempValue);
    } else {
      return false;
    }
  }
}

function filter(val) {
  var preg = /^([0-9]+,?[0-9]{0,5})$/;
  if (preg.test(val) === true) {
    return true;
  } else {
    return false;
  }
}

function objectToQueryString(params) {
  var queryString = Object.keys(params)
    .map((key) => key + "=" + params[key])
    .join("&");

  return queryString;
}

function isEmptyObject(value) {
  if (value == null || value == undefined) return true;
  return Object.keys(value).length === 0 && value.constructor === Object;
}

function objectFromSplitArray(array) {
  let foo = array.reduce(function (obj, str, index) {
    let strParts = str.split("=");
    if (strParts[0] && strParts[1]) {
      //<-- Make sure the key & value are not undefined
      obj[strParts[0].replace(/\s+/g, "")] = strParts[1].trim(); //<-- Get rid of extra spaces at beginning of value strings
    }
    return obj;
  }, {});

  return foo;
}

export {
  showLoading,
  hideLoading,
  randomString,
  stringFormat,
  urlAmigable,
  initDatepicker,
  initAutocomplete,
  filterNumber,
  filterFloat,
  filterEmail,
  formEnabled,
  formReadonly,
  objectFromSplitArray,
  objectToQueryString,
  isEmptyObject,
  dynamicSort,
  rawurldecode
};
