import { useToast } from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";

function errorMessage(_message, _timer) {
  let timer = _timer ? _timer : 4000;
  let toast = useToast();

  toast.error(_message, {
    timeout: timer
  });
}

function doneMessage(_message, _timer) {
  let timer = _timer ? _timer : 4000;
  let toast = useToast();
  
  toast.success(_message, {
    timeout: timer
  });
}

export { errorMessage, doneMessage };
