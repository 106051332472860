<template>
  <div class="product-layout col-lg-3 col-md-4 col-sm-6 col-xs-12" v-cloak>
    <div class="product-item-container item--static">
      <div class="left-block">
        <div class="product-image-container second_img">
          <a href="#!" target="_self" :title="dataProduct.name">
            <img :src="`/uploads/${dataProduct.main_image}`" style="opacity: 3;" class="img-1 img-responsive imagenCatalogo"
              alt="image1" @error="setAltImg" />
            <!-- <img :src="`api/file/${dataProduct.main_image}`" class="img-2 img-responsive" alt="image2"
              @error="setAltImg" /> -->
          </a>
        </div>
        <span class="label-product label-new" v-if="dataProduct.is_new == 1">Nuevo</span>
        <span class="label-product label-sale" v-if="bulk_price.discount > 0">-{{ bulk_price.discount }}%</span>
        <!--quickview-->
        <div class="so-quickview">
          <a class="btn-button quickview quickview_handler visible-lg" href="#!" title="Vista rápida" data-fancybox
            :data-src="`#dialog-content-${dataProduct.id}`">
            <i class="fa fa-search"></i>
            <span>Quick view</span>
          </a>
        </div>
        <!--end quickview-->
      </div>
      <div class="right-block">
        <div class="cartinfo--static">
          <div :class="`${props.modeView == 'list' ? 'hidden' : ''}`">
            <!-- <div class="row"> -->
            <div class="col-xl-4 col-lg-4 col-md-3 col-sm-3 col-xs-4">
              <input type="number" readonly v-model="quantity" class="inputQty"
                :name="`input-qty-${props.dataProduct.code}`" />
            </div>
            <!-- <button type="button" class="wishlist btn-button" title="Add to Wish List" onclick="wishlist.add('60');">
            <i class="fa fa-heart"></i>
            </button> -->
            <div class="col-xl-8 col-lg-8 col-md-9 col-sm-9 col-xs-8">
              <button type="button" class="addToCart" title="Agregar al pedido"
                @click="errorMessage('Debe iniciar sesión para crear un pedido')">
                <span>Agregar</span>
              </button>
            </div>
          </div>
          <!-- <button type="button" class="compare btn-button" title="Compare this Product " onclick="compare.add('60');">
            <i class="fa fa-refresh"></i>
          </button> -->
          <!-- </div> -->
        </div>
        <span class="small text-secondary text-center">{{ dataProduct.code }}</span>
        <h4>
          <a href="#!" :title="dataProduct.name" target="_self" class="product-item-name">
            {{ dataProduct.name }}
          </a>
        </h4>
        <div class="rating" v-if="bulkPrices.length > 0">
          <!-- <label :for="`bulk_price_${dataProduct.id}_${price.max_bulk}`" v-for="(price, index) in bulkPrices"
            :key="index">
            <input type="radio" :id="`bulk_price_${dataProduct.id}_${price.max_bulk}`" @click="setBulkPrice(price)"
              :name="`bulk_price_${dataProduct.id}`" :value="price.id" v-model="bulk_price_id" />
            X {{ price.max_bulk }}
          </label> -->
        </div>
        <!-- <div class="rating"> 
          <span class="fa fa-stack"><i class="fa fa-star fa-stack-2x"></i></span>
          <span class="fa fa-stack"><i class="fa fa-star fa-stack-2x"></i></span>
          <span class="fa fa-stack"><i class="fa fa-star fa-stack-2x"></i></span>
          <span class="fa fa-stack"><i class="fa fa-star fa-stack-2x"></i></span>
          <span class="fa fa-stack"><i class="fa fa-star-o fa-stack-2x"></i></span>
        </div> -->
        <div class="price" v-if="bulk_price">
          <!-- <span class="price-new">
            {{ formatterCurrency(bulk_price.exchange_sale_price) }}
            <br />
            <span class="text-secondary small">Un:
              {{ formatterCurrency(bulk_price.exchange_unit_sale_price) }}</span>
          </span>
          <span class="price-old" v-if="bulk_price.discount > 0">
            {{ formatterCurrency(bulk_price.exchange_original_sale_price) }}
          </span> -->
        </div>
        <div :class="`description item-desc small ${props.modeView == 'grid' ? 'hidden' : ''
          }`" v-html="dataProduct.description">
        </div>
        <div :class="`list-block ${props.modeView == 'grid' ? 'hidden' : 'row'}`">
          <div class="col-md-8 col-sm-12 col-xs-12 text-center">
            <div class="input-group">
              <input type="number" v-model="quantity" class="inputQty form-control"
                :name="`input-qty-${props.dataProduct.code}`" />
              <span class="input-group-btn">
                <button class="addToCart btn btn-primary" type="button" title="Agregar al pedido"
                  @click="errorMessage('Debe iniciar sesión para crear un pedido')">
                  <i class="fa fa-shopping-basket"></i>
                  Agregar
                </button>
              </span>
            </div>
          </div>
          <!-- <button class="wishlist btn-button" type="button" title="Add to Wish List" onclick="wishlist.add('101');"><i
              class="fa fa-heart"></i>
          </button> -->
          <!-- <button class="compare btn-button" type="button" title="Compare this Product" onclick="compare.add('101');"><i
              class="fa fa-refresh"></i>
          </button> -->
          <!--quickview-->
          <!-- <a class="iframe-link btn-button quickview quickview_handler visible-lg" href="#!" title="Vista rápida"
            data-fancybox-type="iframe">
            <i class="fa fa-eye"></i>
          </a> -->
          <!--end quickview-->
        </div>
      </div>
    </div>
    <ModalProductCatalogue :dataProduct="dataProduct" :allowShop="false" @addCart="errorMessage('Debe iniciar sesión para crear un pedido')"></ModalProductCatalogue>
  </div>
</template>

<script setup>
import { errorMessage } from "../js/util/notifications";
import ModalProductCatalogue from "./ModalProductCatalogue.vue";
import { Fancybox } from "@fancyapps/ui";

import {
  ref,
  onMounted,
  getCurrentInstance,
  defineExpose,
  computed,
  watch,
} from "vue";

const props = defineProps({
  modeView: {
    type: String,
  },
  dataProduct: {
    type: Object,
    required: true,
  },
  currency: {
    type: String,
    required: true,
  },
});

const bulk_price = ref({});
const bulk_price_id = ref(null);
const quantity = ref(1);
const loaded = ref(false);

const emit = defineEmits(["addCart"]);

const formatterPercent = (val) => {
  let percent = val / 100;
  return new Intl.NumberFormat("es-AR", {
    style: "percent",
    maximumFractionDigits: 2,
  }).format(percent);
};

const formatterCurrency = (val) => {
  if (props.currency == "USD") {
    return new Intl.NumberFormat("es-AR", {
      style: "currency",
      currency: "USD",
    }).format(val);
  } else {
    return new Intl.NumberFormat("es-AR", {
      style: "currency",
      currency: "ARS",
    }).format(val);
  }
};

const setBulkPrice = (selected_bulk_price) => {
  bulk_price.value = selected_bulk_price;
};

const setAltImg = (event) => {
  event.target.src = '/dist/intermotos/images/logo_not_image.png';
}

onMounted(() => {
  let instance = getCurrentInstance();
  Fancybox.bind("[data-fancybox]", {
    // Your custom options
  });
  loaded.value = true;
});

const bulkPrices = computed(() => {
  let default_bulk_price = {
    id: null,
    max_bulk: 1,
    allow_select_qty: 1,
    original_sale_price: Number(props.dataProduct.original_sale_price),
    sale_price: Number(props.dataProduct.sale_price),
    exchange_unit_sale_price: Number(props.dataProduct.exchange_sale_price),
    unit_sale_price: Number(props.dataProduct.sale_price),
    exchange_original_sale_price: Number(
      props.dataProduct.exchange_original_sale_price
    ),
    exchange_sale_price: Number(props.dataProduct.exchange_sale_price),
    discount: Number(props.dataProduct.discount_percentage),
  };

  let bulk_prices = JSON.parse(
    props.dataProduct.bulk_prices.substring(
      1,
      props.dataProduct.bulk_prices.length - 1
    )
  );

  if (bulk_prices.length > 0) {
    bulk_prices.push(default_bulk_price);
  }

  setBulkPrice(default_bulk_price);

  return bulk_prices.sort((a, b) => {
    return a.max_bulk - b.max_bulk;
  });
});

defineExpose({});
</script>

<style>
.product-item-container .right-block {
  min-height: 140px;
}

.rating label {
  padding: 5px;
}

.product-item-name {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
}
</style>
